import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { AppMain } from './AppMain';

export function OnboardingMain({ children }: PpWC): React.ReactElement {
  const theme = useTheme();
  return (
    <AppMain
      sx={{
        backgroundColor: theme.palette.grayscale.offWhite,
        height: '100%',
        padding: theme.spacing(2),
      }}
      withNavbar={false}
    >
      {children}
    </AppMain>
  );
}
